import React, { useState } from 'react';
import { Row, Col } from '../modules/Grid';
import logo from '../../images/launchpad_logo.png';
import EmailSignup from './EmailSignup';
import { Body, Heading } from '../modules/Typography';
import twitter from '../../images/Twitter.png';
import instagram from '../../images/Instagram.png';
import linkedin from '../../images/Linkedin.png';

const Footer = props => {
    const [emailSent, setEmailSent] = useState(false);

    return (
        <div className='Footer'>
            <Row className='flex center'>
                <Col
                    xs={8}
                    sm={6}
                    md={3}
                    lg={4}
                    xl={3}
                    className='flex column center'
                >
                    <img src={logo} alt='Disney Launchpad Logo' className='Footer__logo' />

                </Col>
                {/* <Col className='Footer__divider' md={1} mdOffset={1} mdOrder={2}><div /></Col>
                <Col
                    xs={8}
                    sm={6}
                    md={3}
                    lg={4}
                    xsOffset={2}
                    smOffset={3}
                    mdOffset={0}
                    mdOrder={3}
                >
                    {emailSent ? (
                        <div className='spacing-lg'>
                            <Heading center number={3}>Email Sign Up</Heading>
                            <Body number={4}>Thanks! We'll keep you up to date.</Body>
                        </div>) : (<EmailSignup handleEmailSent={() => setEmailSent(true)} />)}
                    <div className='Footer__icons flex center'>
                        <a target='_blank' rel='noopener noreferrer' href='https://twitter.com/LaunchpadShorts'><img className='Upload__icon__footer' src={twitter} /></a>
                        <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/launchpadshorts'><img className='Upload__icon__footer' src={instagram} /></a>
                        <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/showcase/disney-launchpad'><img className='Upload__icon__footer' src={linkedin} /></a>
                    </div>
                </Col> */}
            </Row>
            <Row className='spacing-lg'>
                <div className='Footer__links'>
                    <a className='Footer__link' href='https://disneytermsofuse.com/' target='_blank' rel='noopener noreferrer'>
                        Terms of Use
                    </a>
                    <a className='Footer__link' href='https://support.disney.com/hc/en-us/articles/360000829466-Legal-Notices' target='_blank' rel='noopener noreferrer'>
                        Legal Notices
                    </a>
                    <a className='Footer__link' href='https://privacy.thewaltdisneycompany.com/en/' target='_blank' rel='noopener noreferrer'>
                        Privacy Policy
                    </a>
                    <a className='Footer__link' href='https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/your-us-state-privacy-rights' target='_blank' rel='noopener noreferrer'>
                        Your US State Privacy Rights
                    </a>
                    <a className='Footer__link' href='https://privacy.thewaltdisneycompany.com/en/for-parents/childrens-online-privacy-policy/' target='_blank' rel='noopener noreferrer'>
                        {' '}
                        Children's Online Privacy Policy
                    </a>
                    <a className='Footer__link' href='http://preferences-mgr.truste.com/?type=disneycolor&affiliateId=115' target='_blank' rel='noopener noreferrer'>
                        Interest-Based Ads
                    </a>
                    <button id='ot-sdk-btn' className='ot-sdk-show-settings Footer__link'>Do Not Sell or Share My Personal Information</button>
                </div>
            </Row>
        </div>
    );
};

export default Footer;
