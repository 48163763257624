import React from 'react';
// import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import HomeHero from '../home/HomeHero';
import HomeApplication from '../home/HomeApplication';
import HomeSuccess from '../home/HomeSuccess';
import HomeDetails from '../home/HomeDetails';
import HomeStory from '../home/HomeStory';
import HomeTimeline from '../home/HomeTimeline';

const Home = (props) => {
    return (
        <div className='Home'>
            <Helmet>
                <title>Disney Launchpad: Shorts Incubator</title>
            </Helmet>
            <HomeHero />
            {/* <HomeApplication /> */}
            {/* <HomeSuccess /> */}
            {/* <HomeDetails /> */}
            {/* <HomeStory /> */}
            {/* <HomeTimeline /> */}
        </div>
    );
};

Home.propTypes = {};

export default Home;
