import React, { useContext } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { HashLink as Link } from 'react-router-hash-link';
import cross from '../../images/launchpad_menu_cross.svg';
import { UserContext } from '../../contexts/UserContextProvider';

const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
        top: elementPosition,
        left: 0,
        behavior: 'smooth'
    });
};

const MobileMenu = (props) => {
    const { mobileMenuOpen, setMobileMenuOpen } = useContext(UserContext);
    const { applicationsOpen } = useContext(UserContext);

    const toggleMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <Menu
            right
            width='100%'
            isOpen={mobileMenuOpen}
            customCrossIcon={<img src={cross} />}
        >
            {/* <Link
                to='/#programDetails'
                className='menu-item'
                scroll={(el) => scrollWithOffset(el, 52)}
                onClick={toggleMenu}
            >
                Program Details
            </Link>
            <Link
                to='/#howToApply'
                className='menu-item'
                scroll={(el) => scrollWithOffset(el, 52)}
                onClick={toggleMenu}
            >
                How To Apply
            </Link> */}
            {/* <Link
                to='/#lookingFor'
                className='menu-item'
                scroll={(el) => scrollWithOffset(el, 52)}
                onClick={toggleMenu}
            >
                What We're Looking For
            </Link> */}
            <Link
                to='/2020directors'
                className='menu-item'
                scroll={(el) => scrollWithOffset(el, 52)}
            >
                Season One
            </Link>
            <Link
                to='/2021directors'
                className='menu-item'
                scroll={(el) => scrollWithOffset(el, 52)}
            >
                Season Two
            </Link>
            {/* <Link to='/faq' className='menu-item'>
                FAQs
            </Link> */}
            {/* <Link to='/ctdi-programs' className='menu-item'>
                CTDI Programs
            </Link> */}
            {/* <Link to='/news' className='menu-item'>
                News
            </Link> */}
            {/* {applicationsOpen && (
                <Link to='/login' className='menu-item' onClick={toggleMenu}>
                    Apply Now
                </Link>
            )} */}
        </Menu>
    );
};

export default MobileMenu;
