import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container } from '../modules/Grid';
import { Heading } from '../modules/Typography';
import Box from '../modules/Box';

import { WidthContext } from '../../contexts/WidthContextProvider';

const News = (props) => {
    // const { isMobile } = useContext(WidthContext);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const news = [
        {
            id: 'n002',
            title: 'BET+',
            subtitle:
        "2024 NAACP Image Awards: 5 Roles That Showcase Seth Carr's Talent",
            date: 'March 8, 2024',
            link: 'https://www.bet.com/article/ncng4s/2024-naacp-image-awards-5-roles-that-showcase-seth-carrs-talent'
        },
        {
            id: 'n001',
            title: 'NAACP',
            subtitle:
        'Nominees Announced for the 55th NAACP Image Awards',
            date: 'January 25, 2024',
            link: 'https://naacp.org/articles/nominees-announced-55th-naacp-image-awards'
        },
        {
            id: 'n003',
            title: 'DEADLINE',
            subtitle:
        'Disney+ Debuts Posters For 6 New Connection-Themed Short Films',
            date: 'September 21, 2023',
            link: 'https://deadline.com/2023/09/launchpad-season-2-posters-premiere-date-disney-plus-1235552496/'
        },
        {
            id: 'n004',
            title: 'THE NEW YORK TIMES',
            subtitle: 'Disney Creates a ‘Launchpad’ for Underrepresented Filmmakers',
            date: 'February 12, 2022',
            link: 'https://www.nytimes.com/2021/06/02/movies/launchpad-review-disney-plus.html#:~:text=And%20so%20arrives%20%E2%80%9CLaunchpad%2C%E2%80%9D,mentors%20from%20various%20Disney%20divisions'
        },
        {
            id: 'n005',
            title: 'VARIETY',
            subtitle:
        'Disney’s ‘Launchpad’ Short Film Program Announces Season 2 Filmmakers',
            date: 'February 8, 2022',
            link: 'https://variety.com/2022/film/news/disney-launchpad-season-2-underrepresented-filmmakers-1235185429/'
        },
        {
            id: 'n006',
            title: 'AFI',
            subtitle:
        'AFI Announces Disney/AFI Underrepresented Storytellers Initiative',
            date: 'February 4, 2022',
            link: 'https://www.afi.com/news/afi-announces-disney-afi-underrepresented-storytellers-initiative/'
        },
        {
            id: 'n007',
            title: 'ET ONLINE',
            subtitle:
        "Disney's 'Launchpad': Meet the Next Generation of Disney Storytellers",
            date: 'February 3, 2022',
            link: 'https://www.etonline.com/disneys-launchpad-meet-the-next-generation-of-disney-storytellers-exclusive-166094'
        },
        {
            id: 'n008',
            title: 'THE WRAP',
            subtitle:
        'Disney and AFI Launch Underrepresented Storytellers Initiative for New Filmmakers',
            date: 'February 2, 2022',
            link: 'https://www.thewrap.com/disney-afi-underrepresented-storytellers-initiative/'
        }
    ];

    return (
        <div className='News'>
            <Helmet>
                <title>Disney Launchpad: Shorts Incubator | News</title>
            </Helmet>
            <Heading
                className='Directors__heading underline spacing-md'
                center
                number={2}
            >
                News
            </Heading>
            <Container className='flex align-center center'>
                <div className='cardsContainer'>
                    {news.map((item) => (
                        <div className='newsItem' key={item.id}>
                            <a href={item.link} target='_blank' rel='noopener noreferrer'>
                                <div>
                                    <span className='cardTitle'>{item.title}:</span>
                                    <span className='cardSubtitle'>{item.subtitle}</span>
                                </div>
                                <div className='cardDate'>{item.date}</div>
                            </a>
                        </div>
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default News;
